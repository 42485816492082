<template>
  <PayUCheckoutForm
    :amount="amount"
    :description="description"
    :customer-email="customerInfo.email"
    :customer-first-name="customerInfo.firstName"
    :customer-last-name="customerInfo.lastName"
    :customer-phone="customerInfo.phone"
    :currency="currency"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPaymentStrategy } from "./IPaymentStrategy";
import PayUCheckoutForm from "../PayUCheckoutForm.vue";

@Component({
  components: {
    PayUCheckoutForm,
  },
})
export default class PayUStrategy extends Vue implements IPaymentStrategy {
  @Prop({ required: true }) readonly amount!: number;
  @Prop({ required: true }) readonly description!: string;
  @Prop({ required: true }) readonly customerInfo!: any;
  @Prop({ required: true }) readonly currency!: string;

  async initiatePayment(
    amount: number,
    description: string,
    customerInfo: any
  ): Promise<void> {
    // The actual payment initiation is handled by the PayUCheckoutForm component
  }
}
</script>
