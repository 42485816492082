var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-col',{staticStyle:{"background-color":"#ececf3"},attrs:{"cols":"auto","justify":"center"}},[_c('v-row',{staticClass:"flex-lg-row",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"choose-staff-col pt-0",attrs:{"lg":"8","md":"12","sm":"12"}},[_c('v-card',{staticClass:"choose-staff-card",attrs:{"flat":""}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',{class:{ selected: _vm.anyStaffSelected },staticStyle:{"border":"solid 1px lightgray"},on:{"click":_vm.onSelectAnyStaff}},[_c('v-list-item-avatar',{attrs:{"size":"60"}},[_c('v-icon',{attrs:{"size":60,"color":"grey lighten-1"}},[_vm._v(" mdi-account-group ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#1a93d0","text-decoration":"underline"},attrs:{"color":"#1A93D0"}},[_vm._v(" "+_vm._s(_vm.$t("chooseStaff.anyStaffMember"))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("chooseStaff.anyStaffMemberSubtitle"))+" ")])],1)],1),(_vm.isLoading)?_vm._l((_vm.staffMemberCount),function(n){return _c('v-list-item',{key:n,staticStyle:{"border":"solid 1px lightgray"}},[_c('v-list-item-avatar',{attrs:{"size":"60"}},[_c('v-skeleton-loader',{attrs:{"type":"avatar"}})],1),_c('v-list-item-content',[_c('v-skeleton-loader',{attrs:{"type":"text"}}),_c('v-skeleton-loader',{attrs:{"type":"text","width":"60%"}})],1)],1)}):_vm._l((_vm.sortedStaffMembers),function(staff){return _c('v-list-item',{key:staff.id,class:{
                  selected: _vm.selectedStaffMember?.id === staff.id,
                  unavailable: !staff.nextAvailable,
                },staticStyle:{"border":"solid 1px lightgray"},on:{"click":function($event){return _vm.onSelectStaff(staff)}}},[_c('v-list-item-avatar',{attrs:{"size":"60"}},[(staff.profilePictureUrl)?_c('v-img',{attrs:{"src":staff.profilePictureUrl,"alt":staff.fullName}}):_c('v-icon',{attrs:{"size":60,"color":staff.nextAvailable
                        ? 'grey lighten-1'
                        : 'grey lighten-2'}},[_vm._v(" "+_vm._s("mdi-account")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{style:({
                      color: staff.nextAvailable ? '#1a93d0' : '#999',
                      textDecoration: staff.nextAvailable
                        ? 'underline'
                        : 'none',
                      cursor: staff.nextAvailable ? 'pointer' : 'not-allowed',
                    })},[_vm._v(" "+_vm._s(staff.fullName)+" "),(staff.nextAvailable)?_c('span',{staticClass:"next-available"},[_vm._v(" ("+_vm._s(_vm.$t("chooseStaff.nextAvailable"))+": "+_vm._s(_vm.formatNextAvailable(new Date(staff.nextAvailable)))+") ")]):_c('span',{staticClass:"unavailable-text"},[_vm._v(" ("+_vm._s(_vm.$t("chooseStaff.currentlyUnavailable"))+") ")])]),_c('v-list-item-subtitle',{staticStyle:{"white-space":"inherit"}})],1)],1)})],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }