import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { APIURL2 } from "@/main";

// Interfaces
export interface PaymentState {
  formData: PaymentFormData | null;
}

export interface PaymentFormData {
  action: string;
  formFields: { [key: string]: string };
}

export interface InitiatePaymentRequest {
  amount: number;
  currency: string;
  description: string;
  customerEmail: string;
  customerFirstName: string;
  customerLastName: string;
  customerPhone: string;
  successUrl: string;
  failureUrl: string;
}

// State
export const state: PaymentState = {
  formData: null,
};

// Getters
export const getters: GetterTree<PaymentState, any> = {
  formData: (state) => state.formData,
};

// Mutations
export const mutations: MutationTree<PaymentState> = {
  setFormData(state, payload: PaymentFormData) {
    state.formData = payload;
  },
};

// Actions
export const actions: ActionTree<PaymentState, any> = {
  async initiatePayment(
    { commit },
    request: InitiatePaymentRequest
  ): Promise<PaymentFormData> {
    try {
      const { data } = await axios.post(`${APIURL2}payu/initiate`, request);
      commit("setFormData", data);
      return data;
    } catch (error) {
      console.error("Failed to initiate payment:", error);
      throw error;
    }
  },
};

// Export store module
export const payment = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
