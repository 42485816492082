export default {
  currency: "${amount}",
  calendar: {
    noServiceDialogMessage:
      "No providers currently offer this service, please select another service.",
    selectAnotherService: "Select another service",
    availableHoursLabel: "Available ",
    hoursOnDateLabel: "Hours on ",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  chooseStaff: {
    anyStaffMember: "Any Available Staff Member",
    anyStaffMemberSubtitle: "Book with the first available staff member",
    nextAvailable: "Next Available",
    currentlyUnavailable: "Currently Unavailable",
  },
  hourList: {
    noAvailableHours: "No available hours for this day",
    findFirstAvailableHour: "Find first available hour",
  },
  datePicker: {
    cancel: "Cancel",
    ok: "OK",
  },
  bookingForm: {
    selectDepartment: "Select department",
    nameRequired: "Name is required",
    addressRequired: "Address is required",
    postalCodeRequired: "Postal code is required",
    emailRequired: "Email is required",
    emailValid: "Email must be valid",
    phoneRequired: "Phone number is required",
    phoneValid: "Phone number must be valid",
    passwordRequired: "Password is required",
    passwordLength: "Password must be at least 5 characters",
    service: "Service",
    description: "Description",
    time: "Time",
    total: "Total",
    totalDuration: "Total duration",
    selectService: "Please select a service.",
    checkboxTooltipBoth:
      "Check the boxes to receive confirmation/reminder for the appointment",
    checkboxTooltipReminder:
      "Check the box to receive a reminder for the appointment",
    checkboxTooltipReceipt:
      "Check the box to receive confirmation for the appointment",
    emailReminder: "I want a reminder via email",
    emailReceipt: "I want confirmation via email",
    smsReminder: "I want a reminder via SMS",
    smsReceipt: "I want confirmation via SMS",
    whatsappReminder: "I want a reminder via WhatsApp",
    whatsappReceipt: "I want confirmation via WhatsApp",
    newsletterSignup: "Sign me up for the newsletter",
    bookAppointment: "Book appointment",
    agreeWithConsent: "I agree with the cancellation and change rules",
    addComment: "Add a comment",
    phoneLength: "Phone number must be {length} digits",
    invalidCountryCode: "Invalid country code",
  },
  bookingStepper: {
    back: "Back",
    selectService: "Select Service",
    selectServices: "Select Services",
    selectStaff: "Select Staff Member",
    selectTime: "Select Time",
    yourInformation: "Your Information",
    confirm: "Confirm",
    receipt: "Receipt",
    thankYou: "Thank you for your booking!",
    receiptEmail: "A receipt has been sent to your email.",
    reminderEmail: "A reminder will be sent to your email.",
    receiptSMS: "A receipt has been sent to your phone.",
    reminderSMS: "A reminder will be sent to your phone.",
    booking: "Booking",
  },
  chooseService: {
    selectedServices: "Selected services",
    continue: "Continue",
  },
  dialog: {
    description: "Description",
    cancel: "Cancel",
    confirm: "Confirm",
    yes: "Yes",
    no: "No",
  },
  customerAccount: {
    phoneRequired: "Phone number is required",
    phoneValid: "Phone number must be valid",
    codeRequired: "Code is required",
    codeValid: "Code must be valid",
    myPage: "My Page",
    invalidCode: "Invalid code, please try again",
    confirmCancel: "Are you sure you want to cancel this appointment?",
    appointmentCancelled: "Your appointment has been cancelled.",
    cancelLimit: "You can only cancel {hours} hours in advance.",
    appointmentChanged: "You have changed your appointment!",
    changeTime: "Do you want to change the time to {date} {time}?",
    phoneNumber: "Your phone number",
    smsCode: "Code from SMS",
    login: "Log in",
    yourAppointments: "Your Appointments",
    cancellationPolicy:
      "Cancellation/Change of appointment must be made at least {hours} hours before the scheduled time.",
    service: "Service",
    time: "Time",
    move: "Move",
    cancel: "Cancel",
    bookNewAppointment: "Book new appointment",
    editInformation: "Edit your information",
    closeEditing: "Close editing",
    editUser: "Edit user",
    save: "Save",
    name: "Your name",
    phoneNumberOptional: "Phone number (Optional)",
    email: "Email",
    emailOptional: "Email (Optional)",
    address: "Address",
    city: "City",
    postalCode: "Postal code",
    ssn: "Social Security Number",
    birthdate: "Birthdate",
    gender: "Gender",
    male: "Male",
    female: "Female",
    password: "Password",
    sendNewPassword: "Send new password",
    enterCodeBelow: "Enter the code below",
    passwordChanged: "Your password has been changed",
    next: "Next",
    cityRequired: "City is required",
    countryCode: "Country Code",
  },
  serviceItem: {
    noAvailableTimes: "No available times for this service.",
    today: "today",
    tomorrow: "tomorrow",
    dayAfterTomorrow: "day after tomorrow",
    selectAdditionalServices: "Select additional services",
    continue: "Continue",
    confirm: "Confirm",
    firstAvailable: "First available",
    hour: "hour",
    hours: "hours",
    min: "min",
    variablePriceInfo:
      "Price varies depending on the service details. The staff will provide the exact price upon arrival.",
    unavailableOnlineBooking:
      "This service is not available for online booking",
    useWhatsApp: "Please use WhatsApp to book this service",
    whatsAppMessage: "Hi! I would like to book",
    whatsAppDateTime: "for",
  },
  mainApp: {
    myPage: "My Page",
    logout: "Log out",
    leaveBooking: "Do you want to leave the booking?",
  },
  receiptPage: {
    eventTitle: "Appointment at {companyName}",
    addToCalendar: "Add to your calendar",
    bookNewTime: "Book new time",
    goToMyPage: "Go to my page",
  },
  payment: {
    title: "Payment",
    description: "Amount to pay: {amount} {currency}",
    noProviderConfigured:
      "No payment provider has been configured. Please contact the service provider.",
  },
};
