import "babel-polyfill";
import Vue from "vue";

import VueI18n from "vue-i18n";

import en from "./locales/en";
import es from "./locales/es";
import no from "./locales/no";

import "/node_modules/flag-icons/css/flag-icons.min.css";

Vue.use(VueI18n);

const savedLocale = localStorage.getItem("locale");

let defaultLocale = "no";
if (savedLocale) {
  defaultLocale = savedLocale;
}

const i18n = new VueI18n({
  locale: defaultLocale, // Default language
  messages: { en, es, no },
});

export { i18n }; // Export the i18n instance for use in raw typescript files

import App from "./App.vue";

import router from "./router";
import { store } from "./store/index";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
Vue.config.productionTip = false;

export let APIURL2: string;
export let HUBURL: string;
export let USE_DEV_SITE: boolean;
export let IS_DEVELOPING: boolean;

IS_DEVELOPING = window.location.hostname === "localhost";
USE_DEV_SITE = false;

APIURL2 = "https://localhost:5001/api/";
HUBURL = "https://localhost:5001/hubs/";

APIURL2 = "https://timebestillerwebapp.azurewebsites.net/api/";

//APIURL2 = "https://localhost:5001/api/";

try {
  // @ts-ignore
  APIURL2 = azurevar_api_url_token_endazurevar;
} catch (error) {
  console.warn(
    "Failed with environent variables, trying environment variables"
  );

  //this is for vercel, but right now its blocking the azure url
  try {
    if (process.env.VUE_APP_API_URL != null) {
      APIURL2 = process.env.VUE_APP_API_URL;
    }
    if (process.env.VUE_APP_HUB_URL != null) {
      HUBURL = process.env.VUE_APP_HUB_URL;
    }
  } catch (e) {
    console.error("Failed with environent variables");
  }
}

//Force production url for now
APIURL2 = "https://timebestillerwebapp.azurewebsites.net/api/";
//APIURL2 = "https://localhost:5001/api/";
try {
  // @ts-ignore
  HUBURL = azurevar_hub_url_token_endazurevar;
} catch (error) {}

Vue.use(VueGtag, {
  config: { id: "G-11THJNCD93" },
});

// import wb from "./service-worker";
// Vue.prototype.$workbox = wb;

new Vue({
  i18n,
  router,
  store,
  vuetify: vuetify,
  render: (h) => h(App),
  async created() {
    //TODO: Investigate if we're even using signalR anymroe
    // let bookingHourHubURL = HUBURL + "bookingHour";
    // const connection: signalR.HubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl(bookingHourHubURL, {
    //     skipNegotiation: true,
    //     transport: signalR.HttpTransportType.WebSockets,
    //   })
    //   .configureLogging(signalR.LogLevel.Debug)
    //   .build();
    // connection.start().then((x) => {
    //   store.commit("setBookingHourConnection", connection);
    // });
  },
}).$mount("#app");
