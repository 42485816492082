<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

import { IUser } from "../../store/user";
import Loader from "@/components/global/Loader.vue";
import Receipt from "@/views/user/receipt.vue";
import BookingForm from "./bookingForm.vue";
import CustomerInformationForm from "./customerInformationForm.vue";
import Calendar from "@/components/calendar/Calendar.vue";
import SelectService from "./ChooseService.vue";
import ChooseStaff from "./ChooseStaff.vue";
import ReceiptPage from "@/views/user/receipt-page.vue";
import {
  IUserDTO,
  IStaffMemberDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import {
  IappointmentType,
  IAvailableHoursRequest,
  IServiceBookingDetail,
} from "@/store/appointments/appointmentTypes";
import { mutationStringStaff } from "@/store/staff";

import { Watch } from "vue-property-decorator";
import PayUCheckoutForm from "../payment/PayUCheckoutForm.vue";

@Component({
  name: "booking-stepper",
  components: {
    ReceiptPage,
    SelectService,
    ChooseStaff,
    Calendar,
    CustomerInformationForm,
    BookingForm,
    Receipt,
    Loader,
    PayUCheckoutForm,
  },
})
export default class BookingStepper extends Vue {
  @Action public getUserAsAnonymous: Function;

  @Action public getUserByNameAsAnonymous: Function;

  public isProduction: boolean = process.env.NODE_ENV === "production";

  @Getter selectHour: string;
  @Getter customerAccount: string;
  @Getter public userToBook: IUserDTO | null;
  @Getter public selectedStaffMember: IStaffMemberDTO | null;

  @Mutation public setBookingStep: Function;
  @Mutation public setSelectedHour: Function;
  @Mutation public setSelectedDate: Function;

  userDetails: IUser = null;

  loaded: boolean = false;
  reminder: boolean = false;
  receipt: boolean = false;

  public customerInfo = null;
  public selectedServices: IappointmentType[] | null = null;
  public serviceBookingsDetails: any = null;

  @Action public getUserAvailableHoursCache: Function;

  public defaultColor: string = "#5C6BC0";

  public async created(): Promise<void> {}

  @Watch("userToBook")
  onUserToBookChange(newVal: IUserDTO) {
    if (newVal != null) {
      this.loaded = true;
      this.$store.commit("setBookingStep", 1);
      this.$store.commit(mutationStringStaff.SET_SELECTED_STAFF, null);
    }
  }

  get stepNumbers() {
    const canSelectStaff = this.userToBook?.customerCanSelectStaff ?? false;
    return {
      selectService: 1,
      selectStaff: canSelectStaff ? 2 : null,
      selectTime: canSelectStaff ? 3 : 2,
      personalInfo: canSelectStaff ? 4 : 3,
      confirm: canSelectStaff ? 5 : 4,
      receipt: canSelectStaff ? 6 : 5,
    };
  }

  //Selected multiple services
  onSelectServices(services: IappointmentType[]) {
    // Process service selections
    const serviceBookings: any[] = services.map((x: any) => ({
      serviceId: x.id,
      extraServiceIds: (x.children ?? [])
        .filter((c) => c.selected)
        .map((c) => c.id),
    }));

    const serviceBookingsDetails: IServiceBookingDetail[] = services.map(
      (x: any) => ({
        service: x,
        children: (x.children ?? []).filter((c) => c.selected),
      })
    );

    this.selectedServices = serviceBookings;
    this.serviceBookingsDetails = serviceBookingsDetails;

    // Progress to next step
    const nextStep = this.userToBook?.customerCanSelectStaff
      ? this.stepNumbers.selectStaff
      : this.stepNumbers.selectTime;

    this.bookingStep = nextStep;
    this.$store.commit(
      "setStepperTitle",
      this.$t(
        this.userToBook?.customerCanSelectStaff
          ? "bookingStepper.selectStaff"
          : "bookingStepper.selectTime"
      ).toString()
    );
  }

  onSelectStaff(staff: IStaffMemberDTO) {
    this.bookingStep = this.stepNumbers.selectTime;
    this.$store.commit(mutationStringStaff.SET_SELECTED_STAFF, staff);
    this.$store.commit(
      "setStepperTitle",
      this.$t("bookingStepper.selectTime").toString()
    );
  }

  onSelectedHour(hour) {
    this.bookingStep = this.stepNumbers.personalInfo;
  }

  onFetchedCustomerInformation(customerInfo) {
    this.customerInfo = customerInfo;
    this.$store.commit(
      "setStepperTitle",
      this.$t("bookingStepper.booking").toString()
    );
    this.bookingStep = this.stepNumbers.confirm;
  }

  onFinishedBooked(boxInfo) {
    this.bookingStep = this.stepNumbers.receipt;
    this.reminder = boxInfo.reminder;
    this.receipt = boxInfo.receipt;
  }

  goBack() {
    this.$store.commit("goBackBookingStep", 1);
  }

  set bookingStep(value) {
    this.setBookingStep(value);
  }

  get bookingStep() {
    return this.$store.getters.bookingStep;
  }

  get showCalendar() {
    const { selectTime } = this.stepNumbers;
    return this.bookingStep >= selectTime;
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
</script>

<template>
  <div>
    <loader v-if="!loaded"></loader>

    <v-stepper
      color="#5C6BC0"
      flat
      v-model="bookingStep"
      v-if="userToBook != null"
      style="border-radius: 0"
    >
      <v-stepper-header class="hidden-sm-and-down">
        <v-btn
          class="mt-4 ml-2"
          @click="goBack"
          color="#5C6BC0"
          dark
          v-if="
            bookingStep > 1 &&
            bookingStep !== (userToBook.customerCanSelectStaff ? 6 : 5)
          "
          ><v-icon>arrow_back</v-icon> {{ $t("bookingStepper.back") }}</v-btn
        >
        <v-stepper-step
          :complete="bookingStep > 1"
          step="1"
          :color="defaultColor"
          >{{ $t("bookingStepper.selectService") }}</v-stepper-step
        >
        <v-divider></v-divider>

        <template v-if="userToBook.customerCanSelectStaff">
          <v-stepper-step
            :complete="bookingStep > 2"
            step="2"
            :color="defaultColor"
            >{{ $t("bookingStepper.selectStaff") }}</v-stepper-step
          >
          <v-divider></v-divider>
        </template>

        <v-stepper-step
          :complete="bookingStep > (userToBook.customerCanSelectStaff ? 3 : 2)"
          :step="userToBook.customerCanSelectStaff ? 3 : 2"
          :color="defaultColor"
          >{{ $t("bookingStepper.selectTime") }}</v-stepper-step
        >
        <v-divider></v-divider>

        <v-stepper-step
          :complete="bookingStep > (userToBook.customerCanSelectStaff ? 4 : 3)"
          :step="userToBook.customerCanSelectStaff ? 4 : 3"
          :color="defaultColor"
          >{{ $t("bookingStepper.yourInformation") }}</v-stepper-step
        >
        <v-divider></v-divider>

        <v-stepper-step
          :complete="bookingStep > (userToBook.customerCanSelectStaff ? 5 : 4)"
          :step="userToBook.customerCanSelectStaff ? 5 : 4"
          :color="defaultColor"
          >{{ $t("bookingStepper.confirm") }}</v-stepper-step
        >
        <v-divider></v-divider>

        <v-stepper-step
          :complete="
            bookingStep === (userToBook.customerCanSelectStaff ? 6 : 5)
          "
          :step="userToBook.customerCanSelectStaff ? 6 : 5"
          :color="defaultColor"
          >{{ $t("bookingStepper.receipt") }}</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items flat>
        <v-row style="background: #ececf3; min-height: 800px">
          <v-col sm="12" class="pa-0 py-5">
            <v-stepper-content style="padding: 0" step="1">
              <select-service @onselect="onSelectServices"> </select-service>
            </v-stepper-content>

            <template v-if="userToBook.customerCanSelectStaff">
              <v-stepper-content style="padding: 0" step="2">
                <choose-staff
                  v-if="bookingStep === 2"
                  @onselect="onSelectStaff"
                  :selectedServices="selectedServices"
                ></choose-staff>
              </v-stepper-content>
            </template>

            <v-stepper-content
              style="padding: 0"
              :step="userToBook.customerCanSelectStaff ? 3 : 2"
            >
              <v-container fluid class="pt-0">
                <v-col>
                  <calendar
                    @choosehour="onSelectedHour"
                    :user="userToBook"
                    v-if="showCalendar"
                    :selectedServices="selectedServices"
                    :serviceBookingsDetails="serviceBookingsDetails"
                    :adminId="userToBook.userId"
                    :staffMemberId="selectedStaffMember?.id"
                    :userBooking="true"
                    :propAppointmentDistribution="
                      userToBook.appointmentDistributionInMinutes
                    "
                  >
                  </calendar>
                </v-col>
              </v-container>
            </v-stepper-content>

            <v-stepper-content
              style="padding-top: 0px"
              :step="userToBook.customerCanSelectStaff ? 4 : 3"
            >
              <customer-information-form
                :userId="userToBook.userId"
                @fetched="onFetchedCustomerInformation"
                v-if="
                  selectHour &&
                  bookingStep === (userToBook.customerCanSelectStaff ? 4 : 3)
                "
              >
                ></customer-information-form
              >
            </v-stepper-content>

            <v-stepper-content
              style="padding-top: 0px"
              :step="userToBook.customerCanSelectStaff ? 5 : 4"
            >
              <booking-form
                :selectedServices="selectedServices"
                :serviceBookingsDetails="serviceBookingsDetails"
                :userId="userToBook.userId"
                :staffMemberId="selectedStaffMember?.id"
                :customerAccount="customerInfo"
                v-if="
                  selectedServices != null &&
                  selectHour &&
                  bookingStep === (userToBook.customerCanSelectStaff ? 5 : 4)
                "
                @finishedBooked="onFinishedBooked"
              >
              </booking-form>
            </v-stepper-content>

            <v-stepper-content
              :step="userToBook.customerCanSelectStaff ? 6 : 5"
            >
              <v-card
                flat
                v-if="
                  bookingStep === (userToBook.customerCanSelectStaff ? 6 : 5)
                "
              >
                <v-card-title>{{ $t("bookingStepper.thankYou") }}</v-card-title>
                <div
                  v-if="userToBook.customerReceiptAndReminderMode == 'email'"
                >
                  <v-card-subtitle v-if="receipt">{{
                    $t("bookingStepper.receiptEmail")
                  }}</v-card-subtitle>
                  <v-card-subtitle v-if="reminder">{{
                    $t("bookingStepper.reminderEmail")
                  }}</v-card-subtitle>
                </div>
                <div v-if="userToBook.customerReceiptAndReminderMode == 'sms'">
                  <v-card-subtitle v-if="receipt">{{
                    $t("bookingStepper.receiptSMS")
                  }}</v-card-subtitle>
                  <v-card-subtitle v-if="reminder">{{
                    $t("bookingStepper.reminderSMS")
                  }}</v-card-subtitle>
                </div>
                <v-card-text>
                  <receipt></receipt>
                  <receipt
                    :serviceBookingDetails="serviceBookingsDetails"
                  ></receipt>
                </v-card-text>
                <v-card-actions>
                  <receipt-page
                    :serviceBookingDetails="serviceBookingsDetails"
                  ></receipt-page>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-col>
        </v-row>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<style scoped></style>
