import { render, staticRenderFns } from "./ServiceItem.vue?vue&type=template&id=6116f8d6&scoped=true"
import script from "./ServiceItem.vue?vue&type=script&lang=ts"
export * from "./ServiceItem.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6116f8d6",
  null
  
)

export default component.exports