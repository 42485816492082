//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IEditCustomerAccountDTO {
  phoneNumber: string;
  firstName: string;
  postalCode: string;
  city: string;
  country: string;
  address: string;
  emailAddress: string;
  password: string;
  userId: string;
  ssn: string;
  gender: string;
  dateOfBirth: any;
  phoneNumberCountryCode: number;
}
export interface IPostCustomerAccountDTO {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  address: string;
  postalCode: string;
  city: string;
  ssn: string;
  gender: string;
  dateOfBirth?: any;
  password: string;
  adminId: string;
}
export interface ICustomerAccountAnonymousRequest {
  login: string;
  adminId: string;
}
export interface ICustomerAccountRequest {
  login: string;
  password: string;
  adminId: string;
}
export interface IResetPasswordByCode {
  login: string;
  newPassword: string;
  code: string;
  adminId: string;
}
export interface IAppointmentChangeAsCustomAccount {
  appointmentId: number;
  serviceIds: number[];
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  phoneNumber: string;
  customerToken: string;
  adminId: string;
}
export interface IAppointmentAnonymousPostDTO {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  comment: string;
  appointmentTypeId: number;
  phoneNumber: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  cancelUrl: string;
  address: string;
  postalCode: string;
  password: string;
  customerAccountIdentificationCode: string;
  emailReceipt: boolean;
  emailReminder: boolean;
  smsReceipt: boolean;
  smsReminder: boolean;
  whatsappReceipt: boolean;
  whatsappReminder: boolean;
  userId: string;
}
export interface ICustomerAccountDTO {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  gender: string;
  dateOfBirth?: any;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  verified: boolean;
  dateAdded?: any;
  password: string;
  adminUserId: string;
  ssn: string;
  adminId: string;
  phoneNumberCountryCode: string;
}
export interface IAppointmentTypeDTO {
  id: number;
  title: string;
  description: string;
  price: number;
  durationInMinutes: number;
  serviceDurationInMinutes: number;
  image: string;
  order: number;
  categoryName: string;
  categoryId: number;
  discountPrice: number;
  hideInBooking: boolean;
  autoApply: boolean;
  showCustomColor: boolean;
  isExtraService: boolean;
  color: string;
  children: IAppointmentTypeDTO[];
  firstAvailable?: any;
  resources: IResourceDTO[];
  mustPayOnline: boolean;
  payOnlinePrice: number;
}
export enum OnlineTransactionProvider {
  None = 0,
  PayU = 1,
  Stripe = 2,
  Vipps = 3,
}
export interface IUserDTO {
  userId: string;
  staffMemberId: number;
  companyId: number;
  companyRole: number;
  sector: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  gender: string;
  ssn: number;
  organizationNumber: string;
  dateOfBirth?: any;
  profilePictureUrl: string;
  companyName: string;
  treatmentAdress: string;
  signUpDate?: any;
  lastYearlySubscription?: any;
  subscriptionType: number;
  appointmentDistributionInMinutes: number;
  smsVerification: boolean;
  showOccupiedHoursForAnonymousBooking: boolean;
  automaticAddCustomers: boolean;
  abilityToCancel: boolean;
  hoursToCancel: number;
  smsCredits: number;
  emailVerified: boolean;
  bookingLink: string;
  bankAccount: string;
  useKID: boolean;
  verificationType: number;
  customerMergeType: number;
  automaticCreateCustomers: boolean;
  kidLength: number;
  kidMod: number;
  taxPercentage: number;
  dueDateLength: number;
  showInformationBox: boolean;
  informationBoxText: string;
  useCustomerAccounts: boolean;
  customerRequiredEmail: boolean;
  customerRequiredPhoneNumber: boolean;
  customerRequiredName: boolean;
  customerRequiredAddress: boolean;
  customerRequiredPostalCode: boolean;
  customerRequiredCity: boolean;
  customerRequiredSSN: boolean;
  customerRequiredBirthdate: boolean;
  customerRequiredGender: boolean;
  recieveEmailOnAppointment: boolean;
  emailAddressToReceive: string;
  enablePaymentModule: boolean;
  maxDaysInAdvanceForBooking: number;
  minumumHourBeforeBooking: number;
  customerRequiredEmailOptional: boolean;
  customerRequiredPhoneNumberOptional: boolean;
  customerReceiptAndReminderMode: string;
  recieveSMSOnAppointment: boolean;
  phoneNumberToReceive: string;
  multipleServices: boolean;
  showServicePrice: boolean;
  connectedBookingLink: string;
  bookingLocationName: string;
  hideIrrelevantDaysInCalendar: boolean;
  intervalMinutes: number;
  showStaffMemberInEvent: number;
  showTimeInEvent: number;
  showServiceNameInEvent: number;
  activateEmailList: boolean;
  sendAutomaticallyReminder: boolean;
  sendAutomaticallyReceipt: boolean;
  defaultPhoneNumberCountryCode: number;
  customerCanSelectStaff: boolean;
  timeFormat: string;
  onlineTransactionProvider: OnlineTransactionProvider;
  currency: string;
}
export interface IAppointmentInfoDTO {
  id: number;
  number: number;
  customerId: number;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  comment: string;
  adminNote: string;
  duration: number;
  price: number;
  serviceIds: number[];
  customerInsuranceId?: number;
  appointmentTypePrice: number;
  staffMemberName: string;
  staffMemberId: number;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  customerAddress: string;
  customerPostalCode: string;
  customerCity: string;
  appointmentName: string;
  appointmentDuration: string;
  companyName: string;
  companyAddress: string;
  adminEmail: string;
  created: any;
  date?: any;
  createdByCustomer: boolean;
  hasBeen: boolean;
  canCancel: boolean;
  paid: boolean;
  archived: boolean;
  status: string;
  description: string;
  isCustom: boolean;
  GetDate(): any;
  DateIsWithinOneDay(): boolean;
  GetDateEnd(): any;
}
export interface IEditAppointmentDTO {
  id: number;
  customerId: number;
  customerName: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  serviceIds: number[];
  staffMemberId: number;
  comment: string;
  bookedDateTime?: any;
  paid: boolean;
  status: string;
  number: number;
  duration: number;
  price: number;
  adminNote: string;
}
export interface IAppointmentDTO {
  id: number;
  customerId: number;
  customerName: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  appointmentTypeId: number;
  comment: string;
  bookedDateTime?: any;
  paid: boolean;
  status: string;
  number: number;
  duration: number;
  price: number;
  adminNote: string;
  description: string;
  isCustom: boolean;
}
export interface IPostAppointmentDTO {
  id: number;
  forceCreate?: boolean;
  smsReminder: boolean;
  smsReceipt: boolean;
  emailReminder: boolean;
  emailReceipt: boolean;
  whatsappReminder: boolean;
  whatsappReceipt: boolean;
  customerId: number;
  staffMemberId: number;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  serviceIds: number[];
  comment: string;
  bookedDateTime?: any;
  paid: boolean;
  status: string;
  number: number;
  duration: number;
  price: number;
  customerName: string;
  description: string;
}
export interface IAuditLogDTO {
  id: number;
  firebaseUserName: string;
  firebaseUserId: string;
  comment: string;
  message: string;
  type: number;
  action: number;
  time: any;
  hideForUser: boolean;
}
export interface ICustomFieldDTO {
  id: number;
  userId: string;
  label: string;
  optional: boolean;
  archived: boolean;
  enabled: boolean;
  created: any;
}
export interface IResourceDTO {
  id: number;
  userId: string;
  name: string;
  description: string;
  amount: number;
  services: IAppointmentTypeDTO[];
  created: any;
}
export interface IStaffMemberDTO {
  id: number;
  profilePictureUrl: string;
  services: IAppointmentTypeDTO[];
  workingDays: any[];
  vacationDays: any[];
  companyRole: number;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  emailAddress: string;
  gender: string;
  sector: number;
  dateOfBirth?: any;
  mondayOpenFromMidnight: number;
  tuesdayOpenFromMidnight: number;
  wednesdayOpenFromMidnight: number;
  thursdayOpenFromMidnight: number;
  fridayOpenFromMidnight: number;
  saturdayOpenFromMidnight: number;
  sundayOpenFromMidnight: number;
  mondayCloseFromMidnight: number;
  tuesdayCloseFromMidnight: number;
  wednesdayCloseFromMidnight: number;
  thursdayCloseFromMidnight: number;
  fridayCloseFromMidnight: number;
  saturdayCloseFromMidnight: number;
  sundayCloseFromMidnight: number;
  color: string;
}

export interface IStaffMemberBookingDTO extends IStaffMemberDTO {
  nextAvailable?: Date;
}
export interface IUserBookingSettingsDTO {
  mondayOpenFromMidnight: number;
  tuesdayOpenFromMidnight: number;
  wednesdayOpenFromMidnight: number;
  thursdayOpenFromMidnight: number;
  fridayOpenFromMidnight: number;
  saturdayOpenFromMidnight: number;
  sundayOpenFromMidnight: number;
  mondayCloseFromMidnight: number;
  tuesdayCloseFromMidnight: number;
  wednesdayCloseFromMidnight: number;
  thursdayCloseFromMidnight: number;
  fridayCloseFromMidnight: number;
  saturdayCloseFromMidnight: number;
  sundayCloseFromMidnight: number;
}
export interface IUserBookingSettingsAsHoursAndMinutesDTO {
  mondayOpenFromMidnight: string;
  tuesdayOpenFromMidnight: string;
  wednesdayOpenFromMidnight: string;
  thursdayOpenFromMidnight: string;
  fridayOpenFromMidnight: string;
  saturdayOpenFromMidnight: string;
  sundayOpenFromMidnight: string;
  mondayCloseFromMidnight: string;
  tuesdayCloseFromMidnight: string;
  wednesdayCloseFromMidnight: string;
  thursdayCloseFromMidnight: string;
  fridayCloseFromMidnight: string;
  saturdayCloseFromMidnight: string;
  sundayCloseFromMidnight: string;
}
export interface IBookingDTO {
  id: number;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  comment: string;
  serviceId: number;
  phoneNumber: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  cancelUrl: string;
  address: string;
  postalCode: string;
  password: string;
  customerAccountIdentificationCode: string;
  emailReceipt: boolean;
  emailReminder: boolean;
  smsReceipt: boolean;
  smsReminder: boolean;
  whatsappReceipt: boolean;
  whatsappReminder: boolean;
  userId: string;
  created: any;
  verificationCode: string;
  verified: boolean;
  cancelId: string;
  cancelExpiration: any;
  sendReminder: boolean;
  canCancel: boolean;
  staffMemberId: number;
}
export interface IPostBookingDTO {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  comment: string;
  serviceBookings: any[];
  phoneNumber: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  cancelUrl: string;
  address: string;
  postalCode: string;
  password: string;
  customerAccountIdentificationCode: string;
  emailReceipt: boolean;
  emailReminder: boolean;
  smsReceipt: boolean;
  smsReminder: boolean;
  whatsappReceipt: boolean;
  whatsappReminder: boolean;
  signupNewsletter: boolean;
  userId: string;
  city: string;
  phoneNumberCountryCode: string;
}
export interface ICustomerDTO {
  id: number;
  insuranceId: number;
  insuranceName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  gender: string;
  dateOfBirth?: any;
  generalInfo: string;
  address: string;
  postalCode: string;
  country: string;
  city: string;
  ssn: string;
  createdByCustomer: boolean;
  dateAdded?: any;
  sendEmailReminder: boolean;
  sendSmsReminder: boolean;
}
export interface IAppointmentForWeeklyCalender {
  name: string;
  appointmentName: string;
  comment: string;
  adminNote: string;
  customerPhone: string;
  customerEmail: string;
  booked: any;
  start: any;
  end: any;
  color: string;
  staffMemberName: string;
  staffMemberId: number;
  staffMembersProvidingService: number[];
  customerId: number;
  duration: number;
  appointmentId: number;
  appointmentNumber: number;
  appointmentPrice: number;
  day: number;
  month: number;
  year: number;
  hour: number;
  minute: number;
  status: string;
  isCustom: boolean;
  appointmentTypeIds: number[];
  createdByCustomer: boolean;
  category: string;
  isRegularCustomer: boolean;
}
export interface IServiceGroupForBookingDTO {
  order?: number;
  title: string;
  services: IAppointmentTypeDTO[];
}
export interface IAvailableDayRequestDTO {
  year: number;
  month: number;
  day: number;
  serviceIds: number[];
  userId: string;
}
export interface INewCustomerStats {
  monthlyNewCustomers: number[];
  monthlyTotalCustomers: number[];
}
export interface IServiceStat {
  bookings: number;
  serviceName: string;
  color: string;
}
