<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import {
  IUserDTO,
  IStaffMemberBookingDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { actionStringStaff, mutationStringStaff } from "@/store/staff";
import { Prop } from "vue-property-decorator";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";

@Component({
  name: "choose-staff",
})
export default class ChooseStaff extends Vue {
  @Getter public userToBook: IUserDTO;
  @Getter public selectedStaffMember: IStaffMemberBookingDTO | null;
  @Getter public staffMemberCount: number;

  @Prop({ default: null }) selectedServices: any;

  public isLoading: boolean = false;

  @Action(actionStringStaff.GET_STAFF_MEMBERS) getStaffMembers: (params: {
    adminId: string;
    serviceBookings: any[];
  }) => Promise<IStaffMemberBookingDTO[]>;
  @Action(actionStringStaff.GET_STAFF_COUNT) getStaffCount: (
    adminId: string
  ) => Promise<number>;

  public staffMembers: IStaffMemberBookingDTO[] = [];
  public anyStaffSelected: boolean = false;

  formatNextAvailable(date: Date): string {
    const formattedDate = formatDate(date, FormatSpaceType.DOT);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Format time based on user preference
    let timeStr;
    if (this.userToBook.timeFormat === "12h") {
      const period = hours >= 12 ? "PM" : "AM";
      const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
      timeStr = `${displayHours}:${minutes} ${period}`;
    } else {
      // 24h format (default)
      timeStr = `${hours.toString().padStart(2, "0")}:${minutes}`;
    }

    return `${formattedDate} ${timeStr}`;
  }

  get sortedStaffMembers(): IStaffMemberBookingDTO[] {
    return [...this.staffMembers].sort((a, b) => {
      // If one is available and the other isn't, available comes first
      if (a.nextAvailable && !b.nextAvailable) return -1;
      if (!a.nextAvailable && b.nextAvailable) return 1;

      // If both are available, sort by next available time
      if (a.nextAvailable && b.nextAvailable) {
        return (
          new Date(a.nextAvailable).getTime() -
          new Date(b.nextAvailable).getTime()
        );
      }

      // If both are unavailable, maintain original order
      return 0;
    });
  }

  async mounted() {
    await this.getStaffCount(this.userToBook.userId);

    this.isLoading = true;
    this.staffMembers = await this.getStaffMembers({
      adminId: this.userToBook.userId,
      serviceBookings: this.selectedServices,
    });
    this.isLoading = false;
    this.$store.commit(
      "setStepperTitle",
      this.$t("bookingStepper.selectStaff").toString()
    );
  }

  onSelectAnyStaff() {
    this.anyStaffSelected = true;
    this.$store.commit(mutationStringStaff.SET_SELECTED_STAFF, null);
    this.$emit("onselect", null);
  }

  onSelectStaff(staff: IStaffMemberBookingDTO) {
    if (!staff.nextAvailable) return; // Prevent selecting unavailable staff
    this.$store.commit(mutationStringStaff.SET_SELECTED_STAFF, staff);
    this.$emit("onselect", staff);
  }
}
</script>

<template>
  <v-container fluid class="pt-0">
    <v-col style="background-color: #ececf3" cols="auto" justify="center">
      <v-row class="flex-lg-row" justify="center">
        <v-col lg="8" md="12" sm="12" class="choose-staff-col pt-0">
          <v-card class="choose-staff-card" flat>
            <v-list two-line>
              <v-list-item
                @click="onSelectAnyStaff"
                style="border: solid 1px lightgray"
                :class="{ selected: anyStaffSelected }"
              >
                <v-list-item-avatar size="60">
                  <v-icon :size="60" color="grey lighten-1">
                    mdi-account-group
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    color="#1A93D0"
                    style="color: #1a93d0; text-decoration: underline"
                  >
                    {{ $t("chooseStaff.anyStaffMember") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("chooseStaff.anyStaffMemberSubtitle") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <template v-if="isLoading">
                <v-list-item
                  v-for="n in staffMemberCount"
                  :key="n"
                  style="border: solid 1px lightgray"
                >
                  <v-list-item-avatar size="60">
                    <v-skeleton-loader type="avatar" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-skeleton-loader type="text" />
                    <v-skeleton-loader type="text" width="60%" />
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-else>
                <v-list-item
                  v-for="staff in sortedStaffMembers"
                  :key="staff.id"
                  @click="onSelectStaff(staff)"
                  style="border: solid 1px lightgray"
                  :class="{
                    selected: selectedStaffMember?.id === staff.id,
                    unavailable: !staff.nextAvailable,
                  }"
                >
                  <v-list-item-avatar size="60">
                    <v-img
                      v-if="staff.profilePictureUrl"
                      :src="staff.profilePictureUrl"
                      :alt="staff.fullName"
                    ></v-img>
                    <v-icon
                      v-else
                      :size="60"
                      :color="
                        staff.nextAvailable
                          ? 'grey lighten-1'
                          : 'grey lighten-2'
                      "
                    >
                      {{ "mdi-account" }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      :style="{
                        color: staff.nextAvailable ? '#1a93d0' : '#999',
                        textDecoration: staff.nextAvailable
                          ? 'underline'
                          : 'none',
                        cursor: staff.nextAvailable ? 'pointer' : 'not-allowed',
                      }"
                    >
                      {{ staff.fullName }}
                      <span v-if="staff.nextAvailable" class="next-available">
                        ({{ $t("chooseStaff.nextAvailable") }}:
                        {{
                          formatNextAvailable(new Date(staff.nextAvailable))
                        }})
                      </span>
                      <span v-else class="unavailable-text">
                        ({{ $t("chooseStaff.currentlyUnavailable") }})
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="white-space: inherit"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<style scoped>
.choose-staff-col {
  @media (min-width: 1025px) {
    max-width: 75%;
  }
}
.choose-staff-card {
  @media (min-width: 1025px) {
    min-width: 600px;
  }
}

.selected {
  background-color: #f5f5f5;
}

.unavailable {
  background-color: #f9f9f9;
  cursor: not-allowed !important;
}

.next-available {
  font-size: 0.9em;
  color: #666;
  margin-left: 8px;
}

.unavailable-text {
  font-size: 0.9em;
  color: #999;
  margin-left: 8px;
  font-style: italic;
}
</style>
