<script lang="ts">
/* eslint-disable */
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Hour_list from "./HourList.vue";
import Loader from "@/components/global/Loader.vue";
import {
  formatDate,
  FormatSpaceType,
  formatTime,
  castMonthFromNumberToStringName,
} from "@/types/formatDateHelper";
import { DialogMutations, DialogType, IDialog } from "@/store/dialog";
import {
  IAvailableHoursRequest,
  IappointmentType,
  IServiceBookingDetail,
} from "@/store/appointments/appointmentTypes";
import {
  selectedDate,
  selectHour,
  IBookedAppointmentCustomer,
  IBookedAppointments,
} from "@/store/appointments/appointmentTypings";
import {
  actionStringAppointments,
  mutationStringAppointments,
} from "@/store/appointments/appointments";
import {
  actionStringWorkingHours,
  IAvailableHour,
  IWorkingDays,
  mutationStringWorkingHours,
} from "@/store/workingHours";
import { AppointmentDistribution } from "@/types/AppointmentDistribution";
import { IUserDTO } from "@/types/AutoGenerated/reinforcedTypings";
import signalR from "@microsoft/signalr";

import { i18n } from "@/main"; // Import i18n
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

const NOW = new Date();

@Component({
  name: "Calendar",
  components: { Hour_list, Loader },
})
export default class Calendar extends Vue {
  public inst_date: Date = NOW;
  public CURR: Date | null = null;

  public days = [];
  public months = [];

  // Computed methods
  public get currentSelectedDate(): Date | Number | null {
    return this.CURR <= this.daysInMonth ? this.CURR : this.daysInMonth;
  }
  public get currYear(): number {
    return this.inst_date.getFullYear();
  }

  public get currMonth(): number {
    return this.inst_date.getMonth();
  }

  public get currWD(): number {
    return this.inst_date.getDay();
  }

  public get today(): Date | number {
    if (
      this.inst_date.getMonth() == NOW.getMonth() &&
      this.inst_date.getFullYear() == NOW.getFullYear()
    ) {
      return NOW.getDate();
    }
    return 0;
  }

  public get daysInMonth(): Date | number {
    return new Date(this.currYear, this.currMonth + 1, 0).getDate();
  }

  public get getCurrentDayAsString(): string {
    const day = this.inst_date.getDay();
    let dayToSendEnglish = "";
    if (day === 1) {
      dayToSendEnglish = i18n.t("calendar.monday").toString();
    }
    if (day === 2) {
      dayToSendEnglish = i18n.t("calendar.tuesday").toString();
    }
    if (day === 3) {
      dayToSendEnglish = i18n.t("calendar.wednesday").toString();
    }
    if (day === 4) {
      dayToSendEnglish = i18n.t("calendar.thursday").toString();
    }
    if (day === 5) {
      dayToSendEnglish = i18n.t("calendar.friday").toString();
    }
    if (day === 6) {
      dayToSendEnglish = i18n.t("calendar.saturday").toString();
    }
    if (day === 0) {
      dayToSendEnglish = i18n.t("calendar.sunday").toString();
    }
    return dayToSendEnglish;
  }

  // Methods
  public previousMonth() {
    //this.setSelectedHour(null);
    this.inst_date = new Date(this.currYear, this.currMonth - 1);
    this.CURR = null;
  }

  public nextMonth() {
    //this.setSelectedHour(null);
    this.inst_date = new Date(this.currYear, this.currMonth + 1);
    this.CURR = null;
  }

  public get getDateForToday(): any {
    let selectedDateToGetAppointmentsFrom = {};
    let currentDate: Date | Number = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    return (selectedDateToGetAppointmentsFrom = {
      day: day,
      month: month,
      year: year,
    });
  }

  public get urlPath(): string {
    let url: string = this.$route.path;
    return url;
  }

  @Action public getUserAvailableHoursCache: Function;

  //Props
  @Prop({ default: null }) propAppointmentDistribution: number | null;
  @Prop({ default: true }) userBooking: boolean;
  @Prop({ default: null }) adminId: string;
  @Prop({ default: null }) selectedServices: any;
  @Prop({ default: null }) serviceBookingsDetails: any;
  @Prop({ default: null }) staffMemberId: string;
  @Prop({ default: null }) user: IUserDTO;

  @Action public selectDateToBook: Function;
  @Action public selectHourToBook: Function;

  @Mutation(mutationStringAppointments.SET_SELECTED_HOUR) setSelectedHour: (
    hour: IAvailableHour
  ) => void;
  @Mutation(mutationStringAppointments.setSelectedDate) setSelectedDate: (
    payload: null
  ) => void;

  @Getter workingDays: IWorkingDays[];
  @Getter allWorkingDays: IWorkingDays[];

  @Action postSnackbarMessage: Function;
  @Action addToDialogQueue: (dialog: IDialog) => void;
  @Getter public selectHour: IAvailableHour;

  @Getter selectedDate: selectedDate;
  @Getter latestDeletedAppointment: Object | null;
  @Getter userToBook: IUserDTO;

  @Getter bookingHourConnection: signalR.HubConnection;

  @Action getAvailableHours: (
    request: IAvailableHoursRequest
  ) => Promise<IAvailableHour[]>;
  @Action getAvailableHoursForMonth: (
    request: any
  ) => Promise<IAvailableHour[]>;
  @Action getFirstAvailableDay: (
    request: IAvailableHoursRequest
  ) => Promise<Date | null>;
  @Getter availableHours: IAvailableHour[];
  @Getter loadedAvailableHours: IAvailableHour[];

  @Getter firstAvailableDay: Date | null;

  public selectedMonth = 0;
  public selectedYear = 0;
  public showBookingForm: Boolean = false;
  public selectedDay = "";
  public isSelected = null;
  public selectedHour: IAvailableHour | null = null;
  public isClickable: boolean = true;
  public showNoServiceDialog: boolean = false;
  isLoading: boolean = false;

  public vueDatePicker = "";
  public pickerDate = null;

  public requestedMonths: any = [];

  public loadedNextMonth: boolean = false;

  public randomId: string = "";

  public loadedMonthYears: any = [];

  public hasDate = false;

  public hasLoadedAtLeastOneMonth = false;

  // Add this helper function
  private getTimezoneAdjustedDate(date: string | Date): Date {
    try {
      if (date instanceof Date) {
        // Validate the Date object
        if (isNaN(date.getTime())) {
          console.warn("Invalid Date object provided, using current date");
          return new Date();
        }
        // If it's already a Date object, create new date at midnight
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        // For Date objects, we don't need timezone adjustment since they're already in local time
        return newDate;
      }
      // Handle string input
      if (!date) {
        console.warn("Invalid date string provided, using current date");
        return new Date();
      }
      // For date strings like "2024-11-20", create date explicitly in local timezone
      const [year, month, day] = date.split("-").map(Number);
      const localDate = new Date(year, month - 1, day, 0, 0, 0, 0);
      if (isNaN(localDate.getTime())) {
        console.warn("Invalid date string format, using current date");
        return new Date();
      }
      return localDate;
    } catch (error) {
      console.warn("Error processing date:", error);
      return new Date();
    }
  }

  get pickerODate() {
    return this.pickerDate;
  }

  @Watch("pickerODate")
  async onPickerDateChange(newVal, oldVal) {
    console.log("Picker date: " + newVal);
    let splitted = newVal.split("-");
    let year = splitted[0];
    let month = splitted[1];

    if (
      this.loadedMonthYears.find((x) => {
        return x.month == month && x.year == year;
      })
    ) {
      return;
    }

    let newDate = this.getTimezoneAdjustedDate(new Date());
    let day = 1;
    if (newDate.getMonth() + 1 == month) {
      day = newDate.getDate();
    }

    let selectedDateToGetAppointmentsFrom: IAvailableHoursRequest = {
      day: Number(day),
      month: Number(month),
      year: Number(year),
      userId: this.adminId,
      staffMemberId: this.staffMemberId,
      serviceBookings: this.selectedServices,
    };

    await this.getAvailableHoursForMonth(selectedDateToGetAppointmentsFrom);

    await this.getAppointmentsFromDay(day, month, year);

    this.hasLoadedAtLeastOneMonth = true;

    this.loadedMonthYears.push({ month: month, year: year });
  }
  @Watch("allWorkingDays")
  async onNewAvailableHours(newVal, oldVal) {
    if (newVal.includes(true) == false) {
      this.showNoServiceDialog = true;
    }
  }

  public async getAppointmentsFromDay(currDay, months, currYear) {
    this.isLoading = true;

    if (this.canBookThisDay(currDay) == false) {
      this.isLoading = false;
      // setTimeout(() => {
      //   window.scrollTo(0, 1);
      // }, 1);
      return;
    }

    this.selectedMonth = months;
    this.selectedYear = currYear;
    this.CURR = currDay;

    //Setting appointments and workinghours to 0 before fetching new ones
    if (this.isClickable) {
      this.selectedDay = currDay;
      console.log("vueDatePicker", this.vueDatePicker);
      let newDate = this.getTimezoneAdjustedDate(this.vueDatePicker);

      console.log("newDate", newDate);

      const loadedHour: any = this.loadedAvailableHours.find((x: any) => {
        return (
          x.day == newDate.getDate() &&
          x.month == newDate.getMonth() + 1 &&
          x.year == newDate.getFullYear()
        );
      });

      console.log("loadedHour", loadedHour);

      let selectedDateToGetAppointmentsFrom: IAvailableHoursRequest = {
        day: newDate.getDate(),
        month: newDate.getMonth() + 1,
        year: newDate.getFullYear(),
        userId: this.adminId,
        staffMemberId: this.staffMemberId,
        serviceBookings: this.selectedServices,
      };

      console.log(
        "selectedDateToGetAppointmentsFrom",
        selectedDateToGetAppointmentsFrom
      );

      this.selectDateToBook(selectedDateToGetAppointmentsFrom);

      if (loadedHour != null) {
        this.$store.commit("setAvailableHours", loadedHour.hoursToBook);
      } else {
        await this.getAvailableHours(selectedDateToGetAppointmentsFrom);

        //Important: Await getting working hours for the selected day before updating the view
        if (this.firstAvailableDay == null) {
          //TODO: Get it from the websockets...
          await this.getFirstAvailableDay(selectedDateToGetAppointmentsFrom);
        }

        let requestedMonth = this.requestedMonths.find((x) => {
          return (
            x.year == newDate.getFullYear() && x.month == newDate.getMonth() + 1
          );
        });

        //Avoid re-sending multiple heavy requests before getting response
        if (requestedMonth == null) {
          //this.getAvailableHoursForEntireMonth(selectedDateToGetAppointmentsFrom);
          this.requestedMonths.push({
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
          });
        }
      }
      this.isLoading = false;
    }
  }

  get selectedDateString() {
    try {
      if (!this.vueDatePicker) {
        console.warn("No date selected, returning empty string");
        return "";
      }

      const vueDate = this.getTimezoneAdjustedDate(this.vueDatePicker);
      if (isNaN(vueDate.getTime())) {
        console.warn("Invalid date value, returning empty string");
        return "";
      }

      return formatDate(vueDate, FormatSpaceType.DOT);
    } catch (error) {
      console.warn("Error formatting selected date:", error);
      return "";
    }
  }

  reloadPage() {
    location.reload();
  }
  public selectHourByClick(hour: IAvailableHour, i: any): void {
    let vm = this;
    this.showBookingForm = true;
    this.isSelected = i;
    this.selectedHour = hour;
    this.selectHourToBook(hour);
    vm.$emit("choosehour", hour);
  }

  @Watch("inst_date")
  clearAppointmentHoursOnCalendarMonthNavigation() {
    this.setSelectedDate(null);
  }

  @Watch("bookedAppointments")
  onUpdatedBookedAppointments(val: any, old: any) {}

  //Lifecycle

  public async created(): Promise<void> {
    let NOW = this.getTimezoneAdjustedDate(new Date());

    let filteredPreloadedAvailableHours =
      this.$store.getters.preloadedAvailableHours.filter((x) => {
        return (
          JSON.stringify(x.serviceBookings) ===
          JSON.stringify(this.selectedServices)
        );
      });

    this.$store.commit("loadAvailableHours", filteredPreloadedAvailableHours);
  }

  randomString(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  public async mounted(): Promise<void> {
    if (this.loadedAvailableHours.length == 0) {
      this.hasLoadedAtLeastOneMonth = false;
    } else {
      this.hasLoadedAtLeastOneMonth = true;
    }

    this.days = [
      i18n.t("calendar.monday").toString(),
      i18n.t("calendar.tuesday").toString(),
      i18n.t("calendar.wednesday").toString(),
      i18n.t("calendar.thursday").toString(),
      i18n.t("calendar.friday").toString(),
      i18n.t("calendar.saturday").toString(),
      i18n.t("calendar.sunday").toString(),
    ];
    this.months = [
      i18n.t("calendar.january").toString(),
      i18n.t("calendar.february").toString(),
      i18n.t("calendar.march").toString(),
      i18n.t("calendar.april").toString(),
      i18n.t("calendar.may").toString(),
      i18n.t("calendar.june").toString(),
      i18n.t("calendar.july").toString(),
      i18n.t("calendar.august").toString(),
      i18n.t("calendar.september").toString(),
      i18n.t("calendar.october").toString(),
      i18n.t("calendar.november").toString(),
      i18n.t("calendar.december").toString(),
    ];

    await this.setDefaultDate();
  }

  public destroyed(): void {
    this.setSelectedDate(null);
    this.$store.commit("resetAvailableHour", {});
  }

  canBookThisDay(day: any) {
    return true;
  }

  get minimumDatePick() {
    return this.getTimezoneAdjustedDate(new Date()).toISOString();
  }

  get maximumDatePick() {
    //TODO: Get "Booking-settings" from backend

    //TODO: This will not be 100% accurate if time zone differences, but it's good enough cus its not very detemrinemtal
    let now = this.getTimezoneAdjustedDate(new Date());
    let maxDaysInAdvance = this.user.maxDaysInAdvanceForBooking;
    let lastAllowedBookingTime = new Date(
      now.getTime() + maxDaysInAdvance * 24 * 60 * 60 * 1000
    );
    return lastAllowedBookingTime.toISOString();
  }

  selectAppointment(value: any) {
    const service = this.serviceBookingsDetails[0].service; // Get the selected service
    console.log("Service", service);
    if (service && service.unavailableForOnlineBooking) {
      const date = new Date(value.hour.date);

      const formattedDate = formatDate(date, FormatSpaceType.DOT, true);
      const formattedTime =
        this.userToBook.timeFormat === "12h"
          ? formatTime(
              date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
              date.getMinutes()
            ) + (date.getHours() >= 12 ? " PM" : " AM")
          : formatTime(date.getHours(), date.getMinutes());

      const fullPhoneNumber = `${this.userToBook.defaultPhoneNumberCountryCode}${this.userToBook.phoneNumber}`;

      const message = `${this.$t("serviceItem.whatsAppMessage")} ${
        service.title
      } ${this.$t(
        "serviceItem.whatsAppDateTime"
      )} ${formattedDate} ${formattedTime}`;
      const whatsAppLink = `https://wa.me/${fullPhoneNumber}?text=${encodeURIComponent(
        message
      )}`;

      let dialog: IDialog = {
        text:
          this.$t("serviceItem.unavailableOnlineBooking").toString() +
          "\n" +
          this.$t("serviceItem.useWhatsApp").toString(),
        header: service.title,
        type: DialogType.Choice,
        action: () => window.open(whatsAppLink, "_blank"),
      };
      this.$store.commit(DialogMutations.AddToDialogQueue, dialog);
      return;
    }

    this.$store.commit("setStepperTitle", "Din informasjon");
    this.selectHourByClick(value.hour, value.i);
  }

  get labelForCalendar() {
    if (
      this.user != null &&
      this.user.showOccupiedHoursForAnonymousBooking &&
      this.user
    ) {
      return this.$t("calendar.availableHoursLabel").toString();
    } else {
      return this.$t("calendar.hoursOnDateLabel").toString();
    }
  }

  getAppointmentsFromDayChange({ start, end }) {
    const localDate = this.getTimezoneAdjustedDate(this.vueDatePicker);

    this.getAppointmentsFromDay(
      localDate.getDate(),
      localDate.getMonth() + 1,
      localDate.getFullYear()
    );
  }

  gotoFirstAvailableDay(event: any) {
    console.log("gotoFirstAvailableDay", this.firstAvailableDay);
    if (this.firstAvailableDay != null) {
      const localDate = new Date(this.firstAvailableDay);

      const now = this.getTimezoneAdjustedDate(new Date());

      this.vueDatePicker = localDate.toISOString().slice(0, 10);
      this.getAppointmentsFromDay(
        localDate.getDate(),
        now.getMonth() + 1,
        now.getFullYear()
      );
    }
  }

  getEventColor(event) {
    let preloadedEvent = this.loadedAvailableHours.find((x) => {
      return x.iso === event;
    });

    if (preloadedEvent.hoursToBook.length > 3) {
      return "green";
    } else if (preloadedEvent.hoursToBook.length > 0) {
      return "green";
    }

    return "red";
  }

  get preloadedEvents() {
    return this.loadedAvailableHours.map((x) => x.iso);
  }

  loadNewMonth(event) {}

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  async setDefaultDate() {
    try {
      console.log("Set default date....");
      if (!this.serviceBookingsDetails[0]?.service?.firstAvailable) {
        console.log("We dont have it. setting it tomrrow?");
        let now = this.getTimezoneAdjustedDate(new Date());
        now.setDate(now.getDate() + 1);
        this.vueDatePicker = now.toISOString().slice(0, 10);
        this.hasDate = true;
        return;
      }

      console.log(
        "First available date",
        this.serviceBookingsDetails[0].service.firstAvailable
      );

      let defaultDate = new Date(
        this.serviceBookingsDetails[0].service.firstAvailable
      );

      console.log("defaultDate", defaultDate);

      this.vueDatePicker = defaultDate.toISOString().slice(0, 10);
      this.hasDate = true;
    } catch (error) {
      console.warn("Error setting default date:", error);
      // Fallback to tomorrow's date
      const tomorrow = this.getTimezoneAdjustedDate(new Date());
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.vueDatePicker = tomorrow.toISOString().slice(0, 10);
      this.hasDate = true;
    }
  }

  @Watch("user")
  onUserToBookChange(newVal: IUserDTO) {
    if (newVal != null) {
      let now = new Date();
      let selectedDateToGetAppointmentsFrom: IAvailableHoursRequest = {
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear(),
        userId: this.adminId,
        staffMemberId: this.staffMemberId,
        serviceBookings: this.selectedServices || [],
      };

      if (!newVal.multipleServices) {
        this.getUserAvailableHoursCache(selectedDateToGetAppointmentsFrom);
      }
    }
  }
}
</script>

<template>
  <div>
    <v-dialog persistent v-model="showNoServiceDialog" max-width="350px">
      <v-card style="padding: 10px">
        <v-card-text>
          {{ $t("calendar.noServiceDialogMessage") }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="reloadPage">{{
            $t("calendar.selectAnotherService")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="hidden-sm-and-down">
      <v-row justify="center" align-content="center">
        <v-col lg="4" md="12" sm="12" xs="12" class="pt-0">
          <v-date-picker
            v-if="!isMobile && hasDate"
            ref="datepicker"
            style="box-shadow: none"
            full-width
            color="#5C6BC0"
            :locale="$i18n.locale"
            :events="preloadedEvents"
            :event-color="getEventColor"
            :min="minimumDatePick"
            :max="maximumDatePick"
            v-model="vueDatePicker"
            flat
            :picker-date.sync="pickerDate"
            @change="getAppointmentsFromDayChange"
          ></v-date-picker>
        </v-col>

        <v-col lg="7" md="12" sm="12" xs="12" class="pt-0">
          <div v-if="selectedDate && hasLoadedAtLeastOneMonth">
            <hour_list
              @select="selectAppointment"
              :hours="availableHours"
              :selectedHour="selectedHour"
              :isLoading="isLoading"
              :label="labelForCalendar + selectedDateString"
              :user="userToBook"
              :firstAvailableDay="firstAvailableDay"
              @goto="gotoFirstAvailableDay"
            ></hour_list>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="hidden-md-and-up">
      <v-row justify="center" align-content="center">
        <v-col cols="auto" class="pt-0">
          <v-date-picker
            v-if="isMobile && hasDate"
            ref="datepicker"
            style="box-shadow: none"
            full-width
            color="#5C6BC0"
            :locale="$i18n.locale"
            :events="preloadedEvents"
            :event-color="getEventColor"
            :min="minimumDatePick"
            :max="maximumDatePick"
            v-model="vueDatePicker"
            @change="getAppointmentsFromDayChange"
            :picker-date.sync="pickerDate"
            flat
          ></v-date-picker>
        </v-col>

        <v-col class="pt-0" cols="12">
          <div v-if="selectedDate && hasLoadedAtLeastOneMonth">
            <hour_list
              @select="selectAppointment"
              :hours="availableHours"
              :selectedHour="selectedHour"
              :isLoading="isLoading"
              :label="labelForCalendar + selectedDateString"
              :user="userToBook"
              :firstAvailableDay="firstAvailableDay"
              @goto="gotoFirstAvailableDay"
            ></hour_list>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style>
.v-picker__title {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.loader-wrapper {
  width: 100%;
}

.user-booking-size {
  width: 80% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
  .has-been,
  .month-has-been,
  .year-has-been {
    cursor: no-drop;
    pointer-events: none;
    &:hover::after {
      content: "";
    }
  }
}

.selected {
  background: #56dcb6 !important;
  color: white !important;
}
.booked {
  background: #f98295 !important;
  color: #333 !important;
  min-width: 126px;
  &:hover {
    background: #9e1500 !important;
  }
}
.booked-indicator {
  background: #f98295 !important;
  color: white;
}
.hidden {
  display: none;
}
.isWorking {
  background: #d6d6d6 !important;
  color: #c2c2c2 !important;
}
.booking-form {
  width: 80%;
  height: auto;
  margin: 20px 10% 0 10%;
  background: white;
  padding: 68px;
  margin-bottom: 200px;

  .pick-customer-header {
    color: gray;
  }
  .search-for-user {
    background-position: 8px 11px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ccc;
    background-size: 26px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 49px;
    text-indent: 11px;
    margin: 5px 0%;
    border-radius: 3px;
  }
  .pick-treatment-bar {
    transition: 0.4s;
    width: 100%;
    padding: 10px 2px;
    box-sizing: border-box;
    background: #876aff;
    border-radius: 3px;
    select {
      float: right;
      height: 36px;
      border: 2px solid #ffffff2b;
      color: white;
      background: #876aff;
      font-weight: bold;
      margin-right: 9px;
      border-radius: 3px;
    }
    button {
      float: right;
      border-radius: 3px;
      color: white;
      border: none;
      background: #56dcb6;
      color: white;
      margin-right: 9px;
      cursor: pointer;
      font-weight: 600;
      padding: 10px 10px;
    }
    p {
      color: white;
      padding: 9px;
    }
  }
  .customer-wrapper {
    min-height: auto;
    max-height: 139px;
    overflow-y: scroll;
    width: 100%;
    margin-top: -5px;
    transition: 0.3s;
    .customers {
      transition: 0.3s;
      width: 100%;
      background: white;
      border-top: none;
      padding: 15px;
      color: black;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      &:hover {
        background: #ccc;
      }
      &:first-child {
        margin-top: -5px;
      }
    }
  }
}

.hours-wrapper {
  width: 100%;
  background: white;
  height: auto;
  min-height: 200px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 20px;
  .hour-headline {
    margin: 16px 6px;
    width: 100%;
    font-weight: bold;
    color: black;
  }
  .currentSelection {
    background: #56dcb6 !important;
    color: #006a45 !important;
  }
  h3 {
    font-size: 3em;
    color: white;
  }
  .default-month {
    color: white;
  }
  .indicator-wrapper {
    width: 100%;
    margin: 5px;
    .circle-indicator {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background: #f0efef;
    }
    .taken {
      color: #f98295;
    }
    .free {
      color: #ddd;
    }
    .notWorking {
      color: #c2c2c2;
    }
    p {
      float: left;
      text-indent: 5px;
      color: white;
    }
  }
  .simple-line {
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    width: 100%;
    background: white;
  }
  .hour {
    float: left;
    text-align: center;
    border-radius: 3px;
    max-width: 100%;
    background: #f0efef;
    color: #333;
    font-weight: bold;
    width: auto;
    height: auto;
    padding: 6px 22.6px;
    margin: 5px 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px;
    cursor: pointer;
    &:hover {
      background: #e8e8e8;
    }
  }
}
.main-current-date {
  font-size: 2em;
  margin: 20px 0px;
  color: #424242;
}
.calendar-wrapper {
  width: 100%;
  height: auto;
  border-radius: 5px;
  background: white;
  border-right: 4px;
  .calendar {
    width: 100%;
    text-align: center;
    box-shadow: 1px 0px 21px 0px rgba(0, 0, 0, 0.11);
    border-radius: 4px;
    .head,
    .week,
    .days {
      display: flex;

      b {
        color: #000000;
        font-weight: bold;
      }
    }
    .has-been {
      background: #dfdfdf;
      border: 1px solid #d6d5d5;
      color: #bfbfbf;
      background-color: #dedede;
    }

    .today {
      background: #876aff;
      color: white !important;
      border: none;
    }
    .head {
      justify-content: space-between;
      line-height: 40px;
      border-radius: 4px;
    }
  }
}

.week {
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  line-height: 2em;
}
.week b {
  font-weight: normal;
  color: rgba(204, 204, 204, 0.5);
  width: 14.2857142857%;
}
.days {
  flex-wrap: wrap;
  line-height: 51px;
}
span,
time {
  width: 14.2857142857%;
  height: 50px;
  border: 1px solid #fbfbfb;
}
time {
  color: #6c6c6c;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.2em;
}
time:hover {
  background: #efefef;
}
time:hover::after {
  width: auto;
  height: auto;
  z-index: 9991919;
  position: absolute;
  margin-top: -48px;
  border-width: 5px;
  margin-left: 10px;
  background: #62c7c4;
  box-shadow: -1px 2px 21px -4px rgba(0, 0, 0, 0.11);
  transition: 0.3s;
  font-size: 17px;
  padding: 0px 18px;
  box-sizing: border-box;
  -webkit-clip-path: polygon(7% 0, 100% 0%, 100% 94%, 15% 94%, 0 100%, 7% 84%);
  clip-path: polygon(7% 0, 100% 0%, 100% 94%, 15% 94%, 0 100%, 7% 84%);
  color: #fff;
}
// .today {
//   color: white;
//   background: $darkPurple;
//   border-radius: none !important;
//     background: $purple;
//   outline: 2px solid  purple;
//   outline-offset: -5px;
// }
.CURR {
  color: #198ec9 !important;
  background: rgba(25, 142, 201, 0.1);
}
.nextMonth,
.gtMonth {
  cursor: pointer;
  padding: 0 1em;
  font-size: 2em;
  margin: 20px 0px;
  color: #727272;
  img {
    height: 20px;
  }
  .left-arrow {
    transform: rotate(180deg);
  }
}
.nextMonth:hover,
.gtMonth:hover {
  background: rgba(238, 238, 238, 0.2);
}

@media only screen and (max-width: 800px) {
  .calendar-wrapper {
    width: 95%;
    margin: 0px 2.5%;
  }
  .user-booking-size {
    width: 95% !important;
    margin: 0px 2.5% !important;
  }
  time:hover::after {
    display: none;
  }
}
</style>
